import { Config } from "../types/common";

const commonConfig = {
  requestTimeout: 5000,
};

const config: Config = {
  development: {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL ?? "https://scspb-api.dev.ssdemo.ru",
    environment: "development",
    ...commonConfig,
  },
  production: {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL ?? "https://scspb-api.dev.ssdemo.ru",
    environment: 'production',
    ...commonConfig,
  },
};

const environment = process.env.NODE_ENV || "development";
export default config[environment];
